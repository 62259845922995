import React from "react"

import { isBrowser } from "../components/lib/utils"
import { useAuth } from "../components/lib-admin/auth"

const ClearPage = () => {
  const auth = useAuth()

  React.useEffect(() => {
    console.log('Run Clearing')
    if (isBrowser() === true) {
      console.log('clean:local-storage')
      window.localStorage.clear()
      console.log('clean:session-storage')
      window.sessionStorage.clear()
      caches.keys().then(function (keyList) {
        console.log("clean:caches", keyList)
        return Promise.all(
          keyList.map(function (key) {
            console.log("clean:cache", key)
            return caches.delete(key)
          })
        )
      })
      auth.logout();
    }
  }, [auth])

  return (
    <React.Fragment>
      <h1>Clear Page</h1>
      <div>Jetzt sollte wieder alles gehen. <a href="https://cockpit.talk-point.de/">zurück</a></div>
    </React.Fragment>
  )
}

export default ClearPage
